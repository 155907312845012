const graphDescriptions = {
    'Exposure': `This graph shows the probability of hazards. Our model combines global, high-resolution historic
    ﬁre observations from a multi-decadal satellite record with
    historical data on a variety of factors that contribute to hazard
    risk, including precipitation, temperature, topography, land
    cover, ignition sources, and ecology. We then run model
    inferences using ensembled simulations of future weather
    under a variety of scenarios, which lets us provide validated,
    asset-level estimates of future ﬁre risk.`,

    'Financial Impact': `For each hazard, we convert the intensity into an
    expected damage level based on empirically-derived and
    asset type-speciﬁc damage functions (see upper right),
    based on observations of historic damages from insurance
    industry reports and national emergency management
    agencies. Based on the damages across the range of
    predicted intensities, we derive expected annual loss in
    terms of percent of an assets total value.`,

    'Peer Comparison': `For each hazard, we convert the intensity into an
    expected damage level based on empirically-derived and
    asset type-speciﬁc damage functions (see upper right),
    based on observations of historic damages from insurance
    industry reports and national emergency management
    agencies. Based on the damages across the range of
    predicted intensities, we derive expected annual loss in
    terms of percent of an assets total value.`
}

export default graphDescriptions;