import React, { useState, useEffect, useRef } from "react";
import { Map, Source, Layer, MapRef, Popup, Marker } from "react-map-gl";
import { lineString, bbox } from "turf";
import MapFilter from "../Layout/MapFilter";
import {
  assetTypeStyle,
  hazardLabelStyle,
  pointBorder,
} from "../Layout/LayerStyle";

import AdminPolygon from "./AdminPolygon";
import IndexMapLegend from "./IndexMapLegend";
import GraphPanel from "./GraphPanel";

import hazardScoreMetadata from "../../data/geojson/all_region_metadata.json";



const IndexMap = (props) => {
  const { indexData, histogramData, hazards } = props;

  const industries = Array.from(
    new Set(indexData.map((item) => item.industry))
  );
  const indexList = ["EC - DEMO"]; // Future list for PROJECTS
  const temperatureScenarios = ["SSP2-RCP4.5 : 2.4C", "SSPR-RCP8.5 : 4.3C"];
  const yearRanges = ["15yr", "30yr"];


  const [selectedIndex, setSelectedIndex] = useState(indexList[0]);
  const [selectedIndustry, setSelectedIndustry] = useState("Utilities");
  const [selectedIndustryData, setSelectedIndustryData] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedHazard, setSelectedHazard] = useState(null);
  const [selectedTemperatureScenario, setSelectedTemperatureScenario] = useState("4.3°C");
  const [selectedYearRange, setSelectedYearRange] = useState(yearRanges[1]);
  const [graphPanelVisible, setGraphPanelVisible] = useState(false);

  const [activeMapData, setActiveMapData] = useState(null);
  const [assetPointStyle, setAssetPointStyle] = useState(assetTypeStyle);
  const [selectedAssetProperties, setSelectedAssetProperties] = useState(null);    

  const [adminPolyScores, setAdminPolyScores] = useState([]);

  const [assetPointsVisible, setAssetPointsVisible] = useState(true);
  const [popupInfo, setPopupInfo] = useState(null);


  // Extract Industry list from indexData and concatenate all company asset points to single feature collection
  useEffect(() => {
    setCompanies(
      indexData
        .filter((item) => item.industry === selectedIndustry)
        .map((item) => item.company)
    );
    let mapData = {
      type: "FeatureCollection",
      features: [],
    };
    indexData.forEach((item) => {
      if (item.industry === selectedIndustry) {
        mapData.features = mapData.features.concat(item.geojson.features);
      }
    });
    setActiveMapData(mapData);
    setSelectedIndustryData(mapData);
  }, [indexData, selectedIndustry]);

  // Set active map data based on selected company
  useEffect(() => {
    if (selectedCompany) {
      const data = indexData.filter(
        (item) => item.company === selectedCompany
      )[0];
      setActiveMapData(data.geojson);
      setGraphPanelVisible(true);
    }
  }, [indexData, selectedCompany, selectedHazard, selectedTemperatureScenario]);

  // Set active map data based on selected hazard and temperature scenario
  useEffect(() => {
    if (selectedHazard) {
      let scenarioLabel = selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
      const hazardLabel = `${selectedHazard}_${scenarioLabel}_summary_label`;
      const newPointStyle = JSON.parse(JSON.stringify(hazardLabelStyle));
      newPointStyle.paint["circle-color"][1][1] = hazardLabel;
      setAssetPointStyle(newPointStyle);
    } else {
      setAssetPointStyle(assetTypeStyle);
    }
  }, [
    selectedCompany,
    selectedHazard,
    selectedTemperatureScenario,
  ]);


  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const files = importAll(require.context('../../data/geojson/admin_poly_score', false, /\.geojson$/));
    setAdminPolyScores(files);
  }, []);

  
  // Initial Map state
  const mapRef = useRef(null);
  const accessToken = process.env.REACT_APP_MAPBOX_TOKEN; 
  const [viewState, setViewState] = useState({
    latitude: 40.245,
    longitude: -105.79,
    zoom: 3.5,
  });

  // Zoom to active map data extent
  useEffect(() => {
    if (activeMapData && mapRef.current) {
      const pointsList = activeMapData.features.map(
        (feature) => feature.geometry.coordinates
      );
      const [minLng, minLat, maxLng, maxLat] = bbox(lineString(pointsList));
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 200, duration: 500, offset: [100, 0] }
      );
    }
  }, [activeMapData]);

  const onLayerClick = (event) => {
    if (popupInfo) {
      setPopupInfo(null);
    }
    const features = mapRef.current.queryRenderedFeatures(event.point, {
      layers: ["point-layer"],
    });
    if (features.length > 0) {
      const feature = features[0];
      setPopupInfo({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        properties: feature.properties,
      });
      setSelectedAssetProperties(feature.properties);
      setGraphPanelVisible(true);
    } else {
      setPopupInfo(null);
    }
  };

  const handleGraphPanelClose = () => {
    setGraphPanelVisible(false);
    setSelectedAssetProperties(null);
    setPopupInfo(null);
  }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Map
        {...viewState}
        ref={mapRef}
        mapStyle={"mapbox://styles/mapbox/dark-v9"}
        onMove={(event) => setViewState(event.viewState)}
        mapboxAccessToken={accessToken}
        interactive={true}
        scrollZoom={true}
        touchZoomRotate={true}
        dragPan={true}
        onClick={onLayerClick}
      >
        {selectedHazard && (
          <>
          {adminPolyScores.map((poly, index) => (
            <AdminPolygon
              key={index}
              hazardScoreMetadata={hazardScoreMetadata}
              selectedHazard={selectedHazard}
              selectedTemperatureScenario={selectedTemperatureScenario}
              yearRange={selectedYearRange}
              adminPolygonData={poly}
              source_id={`admin-polygon-${index}`}
          />
          ))}
          {assetPointsVisible && (
          <>
              <Source id="point-layer" type="geojson" data={activeMapData}>
          <Layer {...pointBorder} />
          <Layer {...assetPointStyle} />
            </Source>
          </>
        )}
        </>  
        )
        }
        
        {!selectedHazard && assetPointsVisible && (
          <Source id="point-layer" type="geojson" data={activeMapData}>
          <Layer {...pointBorder} />
          <Layer {...assetPointStyle} />
            </Source>
        )}




        {/* {popupInfo && (
          <Popup
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeButton={true}
            onClose={() => handleGraphPanelClose()}
            anchor="top"
          >
            <div >
              <h2 style={{display: 'flex', justifyContent: 'center'}}>{popupInfo.properties.asset_name}</h2>
              <p>{popupInfo.properties.asset_address}</p>
            </div>
          </Popup>
        )} */}
        <MapFilter
          industries={industries}
          indexList={indexList}
          companies={companies}
          selectedIndustry={selectedIndustry}
          setSelectedIndex={setSelectedIndex}
          setSelectedIndustry={setSelectedIndustry}
          setSelectedCompany={setSelectedCompany}
          setGraphPanelVisible={setGraphPanelVisible}
        />
        <IndexMapLegend
          industry={selectedIndustry}
          hazards={hazards}
          hazardScoreMetadata={hazardScoreMetadata}
          selectedHazard={selectedHazard}
          setSelectedHazard={setSelectedHazard}
          temperatureScenarios={temperatureScenarios}
          selectedTemperatureScenario={selectedTemperatureScenario}
          setSelectedTemperatureScenario={setSelectedTemperatureScenario}
          yearRanges={yearRanges}
          selectedYearRange={selectedYearRange}
          setSelectedYearRange={setSelectedYearRange}
          setAssetPointStyle={setAssetPointStyle}
          setActiveMapData={setActiveMapData}
          assetPointsVisible={assetPointsVisible}
          setAssetPointsVisible={setAssetPointsVisible}
        />
        {graphPanelVisible && (
          <GraphPanel
            selectedCompany={selectedCompany}
            handleGraphPanelClose={handleGraphPanelClose}
            selectedAssetProperties={selectedAssetProperties}
            setSelectedAssetProperties={setSelectedAssetProperties}
            selectedIndustryData={selectedIndustryData}
            selectedTemperatureScenario={selectedTemperatureScenario}
            activeMapData={activeMapData}
            histogramData={histogramData}
          />
        )}
      </Map>
    </div>
  );
};

export default IndexMap;
