import React from "react";
import "./ExposureHistogram.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    HorizontalBarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
  );




const ExposureHistogram = (props) => {
    const { 
        selectedTemperatureScenario,
        selectedAssetProperties,
        histogramData,
     } = props;

    let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";

    const analytics = JSON.parse(selectedAssetProperties.scenario_analytics);
    let hazardData = analytics[scenarioLabel].hazard;

    const findBin = (number, breaks) => {
        if (number < breaks[0]) {
            return [-1];
        }

        const result = Array(breaks.length);
        for (let i = 0; i < breaks.length - 1; i++) {
            if (number >= breaks[i] && number < breaks[i + 1]) {
                result[i] = 1;
                return result;
            }
        }

        result[breaks.length - 1] = 100;
        return result;
    };

    const summaryScore = {
        "Wildfire": findBin(hazardData.wildfire.summary_score, histogramData[scenarioLabel].wildfire[1]),
        "Flood": findBin(hazardData.flood.summary_score, histogramData[scenarioLabel].flood[1]),
        "Cyclone": findBin(hazardData.cyclone.summary_score, histogramData[scenarioLabel].cyclone[1]),
        "Heatwave": findBin(hazardData.heatwave.summary_score, histogramData[scenarioLabel].heatwave[1]),
        "SLR": findBin(hazardData.SLR.summary_score, histogramData[scenarioLabel].SLR[1]),
        "Water Stress": findBin(hazardData.water_stress.summary_score, histogramData[scenarioLabel].water_stress[1]),
    }


    let data = {
        "wildfire": {
            labels: histogramData[scenarioLabel].wildfire[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Wildfire,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                },
                {
                    label: 'Wildfire',
                    data: histogramData[scenarioLabel].wildfire[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        },
        "flood": {
            labels: histogramData[scenarioLabel].flood[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Flood,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                },
                {
                    label: 'Flood',
                    data: histogramData[scenarioLabel].flood[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        },
        "cyclone": {
            labels: histogramData[scenarioLabel].cyclone[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Cyclone,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                },
                {
                    label: 'Cyclone',
                    data: histogramData[scenarioLabel].cyclone[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,

                },
            ],
        },
        "heatwave": {
            labels: histogramData[scenarioLabel].heatwave[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Heatwave,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                },
                {
                    label: 'Heatwave',
                    data: histogramData[scenarioLabel].heatwave[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,

                },
            ],

        },
        "SLR": {
            labels: histogramData[scenarioLabel].SLR[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.SLR,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                },
                {
                    label: 'SLR',
                    data: histogramData[scenarioLabel].SLR[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,

                },
            ],
        },
        "water_stress": {
            labels: histogramData[scenarioLabel].water_stress[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore["Water Stress"],
                    backgroundColor: '#ff0000',
                    type: 'scatter',
                },
                {
                    label: 'Water Stress',
                    data: histogramData[scenarioLabel].water_stress[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,

                },
            ],
        },
    };


    const options = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        style: {
            style: {
                height: '10px',
            }
        },
        scales: {
            x: {
              ticks: {
                display: false // This will hide the x-axis labels
              },
              grid: {
                display: true// Optional: also hide the grid lines
              }
            },
            y: {
                beginAtZero: false,
              ticks: {
                display: false // This will hide the y-axis labels
              },
              grid: {
                display: true // Optional: also hide the grid lines
              }
            }
          },
        plugins: {
            legend: {
                display: false,
            },
            labels: {
                display: false,
            },
        },
    };
        


    return (
        <div style={{marginTop: '20px', marginLeft: '25%', height: '85%'}}>
            
             <div style={{ width: '55%', height: '15%', margin: '4px'}}>
                <Bar data={data.wildfire} options={options}/>
            </div> 
            <div style={{ width: '55%', height: '15%', margin: '4px'}}>
                <Bar data={data.flood} options={options}/>
            </div>
            <div style={{ width: '55%', height: '15%', margin: '4px'}}>
                <Bar data={data.cyclone} options={options}/>
            </div>
            <div style={{ width: '55%', height: '15%', margin: '4px'}}>
                <Bar data={data.heatwave} options={options}/>
            </div>
            <div style={{ width: '55%', height: '15%', margin: '4px'}}>
                <Bar data={data.SLR} options={options}/>
            </div>
            <div style={{ width: '55%', height: '15%', margin: '4px'}}>
                <Bar data={data.water_stress} options={options}/>
            </div> 

        </div>
                

    )
}


export default ExposureHistogram;