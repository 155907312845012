import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const BusinessInterruptBarGraph = (props) => {
  const { selectedTemperatureScenario, selectedAssetProperties } = props;

  let scenarioLabel =
    selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";

  const analytics = JSON.parse(selectedAssetProperties.scenario_analytics);
  let hazardData = analytics[scenarioLabel].hazard;

  const businessInterruption = {
    Baseline: {
      Wildfire: hazardData.wildfire.business_interruption.baseline,
      Flood: hazardData.flood.business_interruption.baseline,
      Cyclone: hazardData.cyclone.business_interruption.baseline,
      Heatwave: hazardData.heatwave.business_interruption.baseline,
      lbd:
        (hazardData.wildfire.business_interruption.baseline_lbd +
          hazardData.flood.business_interruption.baseline_lbd +
          hazardData.cyclone.business_interruption.baseline_lbd +
          hazardData.heatwave.business_interruption.baseline_lbd),
      ubd:
        (hazardData.wildfire.business_interruption.baseline_ubd +
          hazardData.flood.business_interruption.baseline_ubd +
          hazardData.cyclone.business_interruption.baseline_ubd +
          hazardData.heatwave.business_interruption.baseline_ubd),
    },
    2030: {
      Wildfire: hazardData.wildfire.business_interruption["2030"],
      Flood: hazardData.flood.business_interruption["2030"],
      Cyclone: hazardData.cyclone.business_interruption["2030"],
      Heatwave: hazardData.heatwave.business_interruption["2030"],
      lbd:
        (hazardData.wildfire.business_interruption["2030_lbd"] +
          hazardData.flood.business_interruption["2030_lbd"] +
          hazardData.cyclone.business_interruption["2030_lbd"] +
          hazardData.heatwave.business_interruption["2030_lbd"]),
      ubd:
        (hazardData.wildfire.business_interruption["2030_ubd"] +
          hazardData.flood.business_interruption["2030_ubd"] +
          hazardData.cyclone.business_interruption["2030_ubd"] +
          hazardData.heatwave.business_interruption["2030_ubd"]),
    },
    2050: {
      Wildfire: hazardData.wildfire.business_interruption["2050"],
      Flood: hazardData.flood.business_interruption["2050"],
      Cyclone: hazardData.cyclone.business_interruption["2050"],
      Heatwave: hazardData.heatwave.business_interruption["2050"],
      lbd:
        (hazardData.wildfire.business_interruption["2050_lbd"] +
          hazardData.flood.business_interruption["2050_lbd"] +
          hazardData.cyclone.business_interruption["2050_lbd"] +
          hazardData.heatwave.business_interruption["2050_lbd"]), 
      ubd:
        (hazardData.wildfire.business_interruption["2050_ubd"] +
          hazardData.flood.business_interruption["2050_ubd"] +
          hazardData.cyclone.business_interruption["2050_ubd"] +
          hazardData.heatwave.business_interruption["2050_ubd"]),
    },
    2080: {
      Wildfire: hazardData.wildfire.business_interruption["2080"],
      Flood: hazardData.flood.business_interruption["2080"],
      Cyclone: hazardData.cyclone.business_interruption["2080"],
      Heatwave: hazardData.heatwave.business_interruption["2080"],
        lbd:
          (hazardData.wildfire.business_interruption["2080_lbd"] +
            hazardData.flood.business_interruption["2080_lbd"] +
            hazardData.cyclone.business_interruption["2080_lbd"] +
            hazardData.heatwave.business_interruption["2080_lbd"]),
        ubd:
          (hazardData.wildfire.business_interruption["2080_ubd"] +
            hazardData.flood.business_interruption["2080_ubd"] +
            hazardData.cyclone.business_interruption["2080_ubd"] +
            hazardData.heatwave.business_interruption["2080_ubd"]),
      },
  };

  const labels = ["Baseline", "2030", "2050", "2080"];

  const wildfireData = labels.map((label) => businessInterruption[label].Wildfire);
  const floodData = labels.map((label) => businessInterruption[label].Flood);
  const cycloneData = labels.map((label) => businessInterruption[label].Cyclone);
  const heatwaveData = labels.map((label) => businessInterruption[label].Heatwave);

  // VIBRANT TONES
  // const data = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: 'Wildfire',
  //       data: wildfireData,
  //       backgroundColor: '#e31a1c',
  //     },
  //     {
  //       label: 'Flood',
  //       data: floodData,
  //       backgroundColor: '#1f78b4',
  //     },
  //     {
  //       label: 'Cyclone',
  //       data: cycloneData,
  //       backgroundColor: '#33a02c',
  //     },
  //     {
  //       label: 'Heatwave',
  //       data: heatwaveData,
  //       backgroundColor: '#ff7f00',
  //     }
  //   ],
  // };

  // MUTED TONES
  const data = {
    labels: labels,
    title: "Business Interruption",
    labelColor: 'white',
    datasets: [
      {
        label: "Lower",
        data: labels.map((label) => businessInterruption[label].lbd),
        backgroundColor: '#505cc5',
        borderColor: '#505cc5',
        borderWidth: 3, 
        pointRadius: 2, 
        type: "line",
        stack: "stack 0",
      },
      {
        label: "Upper",
        data: labels.map((label) => businessInterruption[label].ubd),
        backgroundColor: 'rgba(255, 90, 90, 1)',
        borderColor: 'rgba(255, 90, 90, 1)',
        borderWidth: 3,
        pointRadius: 2,
        type: "line",
        stack: "stack 0",
      },
      {
        label: "Wildfire",
        data: wildfireData,
        backgroundColor: "#fd8d3c",
        stack: "stack 1",
      },
      {
        label: "Flood",
        data: floodData,
        backgroundColor: "#6baed6",
        stack: "stack 1",
      },
      {
        label: "Cyclone",
        data: cycloneData,
        backgroundColor: "#74c476",
        stack: "stack 1",
      },
      {
        label: "Heatwave",
        data: heatwaveData,
        backgroundColor: "#fdae6b",
        stack: "stack 1",
      },
    ],
  };

  const options = {
    indexAxis: "x",
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {      
          color: 'gray',
      },
        stacked: true,
        beginAtZero: true,
      },
      y: {
        stacked: true,
        ticks: {      
          color: 'gray',
      },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          color: 'gray',
        },
      },
      title: {
        display: true,
        text: "Business Interruption (Days)",
        color: "gray",
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BusinessInterruptBarGraph;
