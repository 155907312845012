import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './App.css';
import TopNavBar from './components/Layout/TopNavBar';
import BaseContainer from './components/Layout/BaseContainer';
import LandingPage from './components/LandingPage/LandingPage';
import Portfolio from './components/Portfolio/Portfolio';
import Explore from './components/Explore/Explore';
import Index from './components/Index/Index';
import Report from './components/Report/Report';
import Search from './components/Search/Search';
import Account from './components/Account/Account';
import ProtectedRoute from "./routes/ProtectedRoute";
import ChangePassword from "./components/Account/ChangePassword";
import {AuthProvider} from "./context/authContext";
import UserProfile from "./components/Account/Profile";
import PasswordReset from "./components/Account/PasswordReset";
import PasswordResetDone from "./components/Account/PasswordResetDone";
import Login from "./routes/login/Login";


function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const LOGIN = '/login';
  return (
      <Router>
        <AuthProvider>
          <div >
            {/* <BaseContainer> */}
            <TopNavBar />
            <Routes>
              <Route path="/" element={<LandingPage
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
              />} />
              {/* <Route path="/" element={<Navigate replace to="/index" />} /> */}
              <Route path="/portfolio" element={<ProtectedRoute redirectPath={LOGIN}><Portfolio /></ProtectedRoute>}/>
              <Route path="/explore" element={<ProtectedRoute redirectPath={LOGIN}><Explore /></ProtectedRoute>}/>
              <Route path="/index" element={<ProtectedRoute redirectPath={LOGIN}><Index /></ProtectedRoute>}/>
              <Route path="/report" element={<ProtectedRoute redirectPath={LOGIN}><Report /></ProtectedRoute>}/>
              <Route path="/search" element={<ProtectedRoute redirectPath={LOGIN}><Search /></ProtectedRoute>}/>
              <Route path="/account" element={<ProtectedRoute redirectPath={LOGIN}><Account /></ProtectedRoute>}/>
              <Route path={LOGIN} element={<Login/>}/>
              <Route path="/account/password_reset" element={<PasswordReset/>}/>
              <Route path="/account/password_reset/done" element={<PasswordResetDone/>}/>
              <Route path="/account/password" element={<ProtectedRoute redirectPath={LOGIN}><ChangePassword /></ProtectedRoute>}/>
              <Route path="/account/profile" element={<ProtectedRoute redirectPath={LOGIN}><UserProfile /></ProtectedRoute>}/>
            </Routes>
            {/* </BaseContainer> */}
          </div>
        </AuthProvider>
      </Router>
  );
}

export default App;
