import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    HorizontalBarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    // Title,
    Tooltip,
    // Legend
  );




const ExposureBarGraph = (props) => {
    const { 
        selectedTemperatureScenario,
        selectedAssetProperties,
        histogramData,
     } = props;

    let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";

    const analytics = JSON.parse(selectedAssetProperties.scenario_analytics);
    let hazardData = analytics[scenarioLabel].hazard;


    // const summaryScore = {
    //     "Wildfire": hazardData.wildfire.summary_score, 
    //     "Flood": hazardData.flood.summary_score, 
    //     "Cyclone": hazardData.cyclone.summary_score, 
    //     "Heatwave": hazardData.heatwave.summary_score, 
    //     "SLR": hazardData.SLR.summary_score, 
    //     "Water Stress": hazardData.water_stress.summary_score, 
    // }

    const summaryScore = [
        {"hazard": "Wildfire", "score": hazardData.wildfire.summary_score, "risk_label": hazardData.wildfire.summary_label},
        {"hazard": "Flood", "score": hazardData.flood.summary_score, "risk_label": hazardData.flood.summary_label},
        {"hazard": "Cyclone", "score": hazardData.cyclone.summary_score, "risk_label": hazardData.cyclone.summary_label},
        {"hazard": "Heatwave", "score": hazardData.heatwave.summary_score, "risk_label": hazardData.heatwave.summary_label},
        {"hazard": "SLR", "score": hazardData.SLR.summary_score, "risk_label": hazardData.SLR.summary_label},
        {"hazard": "Water Stress", "score": hazardData.water_stress.summary_score, "risk_label": hazardData.water_stress.summary_label},
    ]

    const structuralDamage = {
        "Wildfire": hazardData.wildfire.structural_damage.baseline * 100, 
        "Flood": hazardData.flood.structural_damage.baseline * 100, 
        "Cyclone": hazardData.cyclone.structural_damage.baseline * 100, 
        // "Heatwave": hazardData.heatwave.structural_damage.baseline * 100, 
        // "SLR": hazardData.SLR.structural_damage.baseline * 100, 
        // "Water Stress": hazardData.water_stress.structural_damage.baseline * 100, 
    }

    const getRiskBarColor = (summary_label) => {
            switch (summary_label) {
              case 'LOW':
                return '#89DA59';
              case 'MEDIUM':
                return '#FBB13C';
              case 'HIGH':
                return '#ff0000';
              default:
                return 'gray';
            }
          };
          



    const chartData = summaryScore;
    const labelValues = chartData.map(item => item.hazard);
    const dataValues = chartData.map(item => item.score);
    const backgroundColors = chartData.map(item => getRiskBarColor(item.risk_label));

    let data = {
        labels: labelValues,
        datasets: [
            {
                label: 'Exposure',
                data: dataValues,
                backgroundColor: backgroundColors,
            },
        ],
    };
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              color: 'gray',
            },
          },
          y: {
            ticks: {
                color: 'gray',
            },
          },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
              display: true,
              text: 'Exposure (% Risk)',
              color: 'gray',
          },
        },
      };


    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#000'}}>
            <Bar data={data} options={options}/>
        </div>
    )
}


export default ExposureBarGraph;