import React, {useContext, useEffect, useState} from 'react';
import {auth, useLocalLogin} from '../../context/firebase-config';
import {Navigate, useNavigate} from "react-router-dom";
import {Field, Form, Formik} from 'formik';
import {signInWithEmailAndPassword} from 'firebase/auth';
import "./Login.css"
import {authContext} from "../../context/authContext";
import {CE_LOGIN} from '../../constants/routes'


const Login = () => {
    let navigate = useNavigate();
    useEffect(() => {
        document.title = "Log In";
    }, []);
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);
    const initialValues = {email: "", password: "",}
    const user = useContext(authContext);

    const validateForm = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    }

    const onLogin = (values) => {
        setLoading(true);
        signInWithEmailAndPassword(auth, values.email, values.password)
            .then((userCredential) => {
                setLoading(false);
                navigate("/index");
            })
            .catch((error) => {
                setLoading(false);
                setErrors("Invalid email/password");
            });
    }

    if (user) {
        return <Navigate to={'/index'} replace/>;
    }

    if (useLocalLogin) {
        return (<>
            <main>
                <section style={{marginTop: 30}}>
                    <div className="login-display-root">
                        <div>
                            <section>
                                <div className="messages d-flex justify-content-center centered-form"
                                     style={{display: "inline-block"}}>
                                    {errors && <div
                                        className="alert alert-danger alert-dismissible fade show"
                                        style={{color: "red"}}>
                                        {errors && errors}
                                    </div>}
                                </div>
                                <div className="d-flex justify-content-center centered-form">
                                    <Formik initialValues={initialValues} validate={validateForm}
                                            onSubmit={(values) => onLogin(values)}>
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                              isSubmitting
                                          }) => (<Form>
                                            <h3 style={{textAlign: "center"}}>Log In</h3>
                                            <div>
                                                <div className="form-group mt-2">
                                                    <Field type="email" id="email" name="email" value={values.email}
                                                           className="textinput form-control"
                                                           maxLength="254"
                                                           size="35"
                                                           onChange={handleChange}
                                                           placeholder="Email address"/>
                                                    <p style={{color: 'red'}}>
                                                        {errors.email && touched.email && errors.email}
                                                    </p>
                                                </div>
                                                <div className="form-group mt-2">
                                                    <Field type="password" id="password" name="password"
                                                           className="textinput form-control"
                                                           value={values.password} onChange={handleChange}
                                                           placeholder="Password"/>
                                                    <p style={{color: 'red'}}>
                                                        {errors.password && touched.password && errors.password}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group mt-3">
                                                <button type="submit" className="btn btn-primary"
                                                        style={{width: "330px", fontWeight: 600}}>
                                                    <span>{loading ? "Logging in..." : " Log In "}</span>
                                                </button>
                                            </div>
                                            <div className="form-group mt-2" style={{textAlign: "center"}}>
                                                <a href={"/account/password_reset"}
                                                   style={{fontWeight: 450, textDecoration: "none"}}> Forgot
                                                    password?</a>
                                            </div>
                                        </Form>)}
                                    </Formik>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </main>
        </>)
    }

    return window.location.replace(CE_LOGIN);
}

export default Login